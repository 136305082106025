import Highway from '@dogstudio/highway';
import {selectAll,select,scrollTo} from './utils';

class CustomRenderer extends Highway.Renderer {

  constructor(props) {
    super(props);

  }

  onEnter() {

    this.properties.renderer.then(() => {
      this.links = selectAll('a[href^="#"]', select('main'));
      this.links.map(link => {
        link.addEventListener('click', scrollTo);
      });

      var oldScript = document.querySelector("[src='https://api.reqruiting.com/scripts/api/da-dk/api_v7.min.js']");
      console.log('oldScript',oldScript);
      if(oldScript) {
        oldScript.remove();
      }

      var newScript = document.createElement("script");
      newScript.src = "https://api.reqruiting.com/scripts/api/da-dk/api_v7.min.js";
      document.body.appendChild(newScript);
    });
  }

  onLeave() {

   }

  onEnterCompleted() { }
  onLeaveCompleted() {

  }
}

// Don`t forget to export your renderer
export default CustomRenderer;
