import BaseComponent from './baseComponent';
import {selectAll,scrollTo} from '../utils';
import gsap from 'gsap';
import {H} from '../routing';

export default class menu extends BaseComponent {
  constructor() {
    super();

    this.hideMenu = this.hideMenu.bind(this);
    this.desktopMenu = document.querySelector('[data-menu-desktop]');
    this.mobileMenu = document.querySelector('[data-menu-mobile]');
    this.overlayToogle = this.mobileMenu.querySelector('button');
    this.overlayMenu = document.querySelector('[data-menu-overlay]');

    this.state = {
      scrollY: 0,
      hasOverlay: false,
      isAnimation:false,
    };

    H.on('NAVIGATE_IN', this.hideMenu);


    this.setup();
  }

  setup() {
    console.log('init!');
    // gsap.to(this.desktopMenu, {
    //   scrollTrigger: {
    //     scrub: true,
    //     onUpdate: ({direction}) => {
    //       if(direction > 0) {
    //         this.hide();
    //       } else {
    //         this.show();
    //       }
    //     },
    //   },
    // });

    this.overlayToogle.addEventListener('click', this.toggleMenu.bind(this));

    this.links = selectAll('[data-scroll-to]',this.desktopMenu).map(link => {
      link.addEventListener('click', scrollTo);
    });
  }

  show() {
    console.log('show!');
    gsap.to([this.desktopMenu,this.mobileMenu], {
      yPercent:0,
      duration:0.45,
    });
  }

  hide() {
    console.log('hide!');
    gsap.to([this.desktopMenu,this.mobileMenu], {
      yPercent:-100,
      duration:0.7,
    });
  }


  hideMenu() {
    this.isAnimation = true;
    this.state.hasOverlay = false;

    gsap.fromTo(this.overlayMenu, {
      autoAlpha:1,
    }, {
      autoAlpha:0,
      duration:0.35,
      ease:'power2.inOut',
      onComplete: () => {
        this.isAnimation = false;
        this.overlayMenu.style.visibility = 'hidden';
      },
    });
  }


  toggleMenu() {
    if(this.state.isAnimation) return;

    if(!this.state.hasOverlay) {

      console.log('open');
      this.isAnimation = true;
      this.state.hasOverlay = true;
      gsap.fromTo(this.overlayMenu, {
        autoAlpha:0,
      }, {
        autoAlpha:1,
        duration:0.35,
        ease:'power2.inOut',
        onComplete: () => {
          this.isAnimation = false;
        },
      });

    } else {
      this.hideMenu();
    }
  }
}
