import './routing';
import store from './global/store';
import {onReady,onResize} from './global/renderer';
import Menu from './components/menu';
console.log('running!');
document.addEventListener('DOMContentLoaded', () => {

	store.windowWidth = window.innerWidth;
  store.windowHeight = window.innerHeight;

  new Menu();

	onReady();
}, false);


let resizeTimeout = setTimeout(()=>{},0);

window.onresize = function(){

	/* --- Clear the timeout if actively resizing --- */
	clearTimeout(resizeTimeout);

	/* --- Delay resize event --- */
	resizeTimeout = setTimeout(()=>{

		/* --- Keep these up to date --- */
		store.windowWidth = window.innerWidth;
		store.windowHeight = window.innerHeight;

		/* --- Fire onResize --- */
		onResize();
	}, 250);
};
