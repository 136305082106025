/* eslint-disable */
import "./init";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";



var bookWidget = document.querySelector("[data-book-widget]");
var bookTrigger = document.querySelector("[data-book-trigger]");
var bookOverlay = document.querySelector("[data-book-overlay]");
var bookClose = document.querySelector("[data-book-close]");

var bookTriggerClose = document.querySelector("[data-book-trigger-close]");

var bookForms = document.querySelectorAll("[data-booking-form] form");


bookTriggerClose.addEventListener("click", (e) => {
  e.target.parentElement.style.display = "none";

  gsap.to(bookWidget, {
    autoAlpha: 0,
    yPercent: 120,
  });
});

bookTrigger.addEventListener("click", (e) => {
  e.preventDefault();

  gsap.to(bookOverlay, {
    autoAlpha: 1,
  });
});

bookClose.addEventListener("click", (e) => {
  e.preventDefault();
  gsap.to(bookOverlay, {
    autoAlpha: 0,
  });
});

bookForms.forEach((bookForm) => {

  var bookMsg = bookForm.querySelector("[data-booking-form-msg]");
  bookForm.addEventListener("submit", (e) => {

      e.preventDefault();

      e.target.querySelector('[type="submit"]').value = "Sender";

      fetch("https://api.form-data.com/f/5lvyngjwconnz7vzhd9i6r?", {
        method: "post",
        body: JSON.stringify({
          email: e.target.querySelector('[name="email"]').value,
          phone: e.target.querySelector('[name="phone"]').value,
          navn: e.target.querySelector('[name="navn"]').value,
          besked: e.target.querySelector('[name="besked"]').value,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((data) => {

          bookMsg.style.display = "block";
          bookForm.style.display = "none";

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
          'event': 'Pageview',
          'pagePath':  '/contact-success',
          'pageTitle': 'Contact success', //some arbitrary name for the page/state
          });
        })
        .catch((error) => {
          console.log("done", error);
          bookMsg.style.display = "block";
          bookForm.style.display = "none";
        });
    });
});

gsap.registerPlugin(ScrollTrigger);
